import React, { useEffect } from 'react';
import * as d3 from 'd3';

const NetworkGraph = ({ data, svgRef }) => {

  useEffect(() => {

    const svg = d3.select(svgRef.current)
                 .attr("version", "1.1")
                .attr("xmlns", "http://www.w3.org/2000/svg")
                .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
                .attr("id", "network");
    const width =300;
    const height = 300;
    const padding = 20;
    const rectWidth = 200;
    const rectHeight = 20;
    var reDrawRef = false;

      function dragStarted(event, d) {
      d3.select(this).raise().classed('active', true);
    }

    function dragged(event, d) {
      d.x = event.x;
      d.y = event.y;
      d.fx = d.x;
      d.fy = d.y;
      calculateLinkPositions();
      drawNetwork();
    }

    function dragEnded(event, d) {
      d3.select(this).classed('active', false);
      d.fx = null;
      d.fy = null;
    }

     // Create a drag behavior
    const drag = d3.drag()
      .on('start', dragStarted)
      .on('drag', dragged)
      .on('end', dragEnded);

    function initializePositions() {
      data.nodes.forEach((node) => {
        // Initialize positions closer to the center of the canvas
        node.x = width / 2 + (Math.random() - 0.5) * padding;
        node.y = height / 2 + (Math.random() - 0.5) * padding;
        node.shape = 'circle'; // Initial shape is a circle
      });
    }

    function calculateForces() {
      const k = 1; // Increased repulsive force
      data.nodes.forEach((node) => {
        node.fx = 0;
        node.fy = 0;

        data.nodes.forEach((otherNode) => {
          if (node !== otherNode) {
            const dx = otherNode.x - node.x;
            const dy = otherNode.y - node.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            // Repulsive force
            const repulsiveForce = k / distance;
            node.fx -= repulsiveForce * dx;
            node.fy -= repulsiveForce * dy;
          }
        });
      });
    }

    function updatePositions() {
      const alpha = 0.1; // Convergence speed
      data.nodes.forEach((node) => {
        node.x += alpha * node.fx;
        node.y += alpha * node.fy;

        // Ensure nodes stay within the canvas boundaries
        node.x = Math.max(padding, Math.min(width - padding, node.x));
        node.y = Math.max(padding, Math.min(height - padding, node.y));
      });
    }

    function calculateLinkPositions() {
      data.links.forEach((link) => {
        const sourceNode = data.nodes.find((node) => node.id === link.source);
        const targetNode = data.nodes.find((node) => node.id === link.target);

        if (sourceNode && targetNode) {
          // Set link positions based on "linkPos1" and "linkPos2" for "binding_region" and "dip" types
          if ((link.interaction === 'binding_region' || link.interaction === 'dip')) {
            link.x1 = sourceNode.x + ((link.linkPos1  * rectWidth) / sourceNode.length) - rectWidth/2;
            link.y1 = sourceNode.y;
            link.x2 = targetNode.x + ((link.linkPos2  * rectWidth) / targetNode.length) - rectWidth/2;
            link.y2 = targetNode.y;
          } else {
            // For other interaction types, set link positions closer to the nodes
            link.x1 = sourceNode.x;
            link.y1 = sourceNode.y;
            link.x2 = targetNode.x;
            link.y2 = targetNode.y;
          }
        }
      });
    }

function drawNetwork() {
  // Remove existing elements
  svg.selectAll('*').remove();

  // Draw the links
  svg.selectAll('line')
    .data(data.links)
    .enter()
     .filter(d => d.interaction === "gene")
    .append('line')
    .attr("class", "links")
    .attr('x1', (d) => d.x1)
    .attr('y1', (d) => d.y1)
    .attr('x2', (d) => d.x2)
    .attr('y2', (d) => d.y2)
    .attr("cursor", "crosshair")
    .style("stroke-width", 1.3)
    .attr('stroke', (d) => {
      const colorScale = d3.scaleOrdinal(d3.schemePastel2).domain(["dip", "biding_region", "gene"]);
      return colorScale(d.interaction);
    });


  // Draw the nodes
  svg.selectAll('g.node')
    .data(data.nodes)
    .enter()
    .append('g')
    .attr('class', 'node')
    .attr('transform', (d) => `translate(${d.x},${d.y})`)
    .attr("cursor", "crosshair")
    .each(function (d) {
      const nodeGroup = d3.select(this);

      // Add title to the node with the name of the node
      nodeGroup.append('title').text(d.name);

      if (d.shape === 'circle') {
        // Draw circle
        nodeGroup.append('circle')
          .attr('r', Math.log(d.length * 100))
          .attr('fill', '#ffffff')
          .attr('stroke', '#000000')
          .attr('stroke-width', 2);

        // Add title to the circle with domain information
        if (d.domains) {
          const pie = d3.pie().value((domain) => domain.end - domain.start);
          const arc = d3.arc().innerRadius(0).outerRadius(Math.log(d.length * 100));

          nodeGroup.selectAll('path')
            .data(pie(d.domains))
            .enter()
            .append('g')
            .append('path')
            .attr('d', arc)
            .attr('fill', (domain) => `#${domain.data.color}`)
            .attr('stroke', (domain) => `#${domain.data.color}`)
            .append('title') // Add title for each domain
            .text((domain) => `${domain.data.name}: ${domain.data.start}-${domain.data.end}`);
        }
      } else if (d.shape === 'rectangle') {
        // Draw rectangle
        nodeGroup.append('rect')
          .attr('x', -rectWidth / 2)
          .attr('y', -rectHeight / 2)
          .attr('width', rectWidth)
          .attr('height', rectHeight)
          .attr('fill', '#ffffff') // Change color to white
          .attr('stroke', '#000000');

        // Add title to the rectangle with domain information
        if (d.domains) {
          nodeGroup.append('title')
            .text(() => {
              return d.domains.map(domain => `${domain.name}: ${domain.start}-${domain.end}`).join('\n');
            });
        }

        // Draw rectangles inside the rectangular shape based on domains
        if (d.domains) {
          d.domains.forEach((domain) => {
            const domainStart = (domain.start  * rectWidth) / d.length;
            const domainEnd = ((domain.end)   * rectWidth + 30) / d.length;
            const domainColor = `#${domain.color}`;

            nodeGroup.append('rect')
              .attr('x', -rectWidth / 2 + domainStart)
              .attr('y', -rectHeight / 2 + 1.40)
              .attr('width', domainEnd - domainStart)
              .attr('height', rectHeight - 3)
              .attr('fill', domainColor)
              .attr('stroke', domainColor)
              .append('title') // Add title for each domain
              .text(`${domain.name}: ${domain.start}-${domain.end}`);
          });
        }

        // Add node label on the left side for rectangular shape
        nodeGroup.append('text')
          .attr('x', -rectWidth / 2 - 5)
          .attr('y', 0)
          .attr('dy', '0.35em')
          .attr('text-anchor', 'end')
          .attr('fill', 'black')
          .style("text-shadow", "-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white")
          .text(d.id)

        // Add length labels below the rectangular node
        const lengthLabelsY = rectHeight / 2 + 15;
        const fractionPositions = [0, 0.25, 0.5, 0.75, 1];

        fractionPositions.forEach((position) => {
          const labelText = position === 0 ? '1' : Math.round(position * d.length).toString();
          const labelX = -rectWidth / 2 + position * rectWidth;

          nodeGroup.append('text')
            .attr('x', labelX)
            .attr('y', lengthLabelsY)
            .attr('dy', '0.35em')
            .attr('text-anchor', 'middle')
            .attr('fill', 'black')
            .text(labelText)
            .style("text-shadow", "-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white")
            .style('font-size', '0.75rem');
        });
      }

      // Add node label for circular shape
      if (d.shape === 'circle') {
        nodeGroup.append('text')
          .attr('dy', -Math.log(d.length * 100) - 5)
          .attr('text-anchor', 'middle')
          .attr('fill', 'black')
          .text(d.id);
      }
    }).call(drag); // Apply the drag behavior to nodes

    svg.selectAll('line')
    .data(data.links)
    .enter()
     .filter(d => d.interaction !== "gene")
    .append('line')
    .attr("class", "links")
    .attr('x1', (d) => d.x1)
    .attr('y1', (d) => d.y1)
    .attr('x2', (d) => d.x2)
    .attr('y2', (d) => d.y2)
    .attr("cursor", "crosshair")
    .style("stroke-width",1.3)
    .attr('stroke', (d) => {
      const colorScale = d3.scaleOrdinal(d3.schemePastel2).domain(["dip", "biding_region", "gene"]);
      return colorScale(d.interaction);
    }).style('visibility', (d) => {
    if (reDrawRef) {
      // Check visibility for binding_region and dip links
      if ((d.interaction === 'binding_region' || d.interaction === 'dip') && d.visible !== undefined) {
        return d.visible ? 'visible' : 'hidden';
      } else {
        return 'visible';
      }
    } else {
      return 'hidden';
    }
  })
    .filter(d => d.interaction === "dip")
    .style("stroke-linecap", "round")
    .style("stroke-linejoin", "round")
    .style("stroke-width", d => Math.log(d.score) )
    .style("stroke-dasharray", ("10,3"));

    svg.selectAll('.links')
     .append('title')
                .text((d) => {
                   if (d.interaction === "dip") {
                            return `${d.source}:${d.linkPos1} - ${d.target}:${d.linkPos2}\nDistance: ${d.score.toFixed(3)}`;
                          } else if (d.interaction === "binding_region") {
                            return `${d.source} - ${d.target}\nMI Score: ${d.score}\nBinding region interactor A: ${d.binding_a}\nBinding region interactor B: ${d.binding_b}\nInteraction type: ${d.interaction_type}`;
                          } else if (d.interaction === "gene" && !d.interaction_type){
                            return `${d.source} - ${d.target}`;
                          }else if (d.interaction === "gene" && d.interaction_type){
                             return `${d.source} - ${d.target}\nInteraction type: ${d.interaction_type}\nMI Score: ${d.score}`;
                          }
                });

}
    function handleMouseClick(event, d) {
     event.preventDefault();
    const [mouseX, mouseY] = d3.pointer(event);

  // Check if a node is clicked
  data.nodes.forEach((node) => {
    const distance = Math.sqrt((mouseX - node.x) ** 2 + (mouseY - node.y) ** 2);

    if (
      (node.shape === 'circle' && distance <= Math.log(node.length * 100)) ||
      (node.shape === 'rectangle' &&
        mouseX >= node.x - rectWidth / 2 &&
        mouseX <= node.x + rectWidth / 2 &&
        mouseY >= node.y - rectHeight / 2 &&
        mouseY <= node.y + rectHeight / 2)
    ) {
      // Toggle the shape state when a node is clicked
      node.shape = node.shape === 'circle' ? 'rectangle' : 'circle';

      // Check if it's a source or target node of dip or binding region links and if the nodes are open
      data.links.forEach((link) => {
        if (
          (link.interaction === 'binding_region' || link.interaction === 'dip') &&
          (node.id === link.source || node.id === link.target)
        ) {
          const sourceNode = data.nodes.find((n) => n.id === link.source);
          const targetNode = data.nodes.find((n) => n.id === link.target);
          if (
            sourceNode &&
            targetNode &&
            sourceNode.shape === 'rectangle' &&
            targetNode.shape === 'rectangle'
          ) {
            reDrawRef = true;
            link.visible = true; // Toggle visibility
          } else {
            link.visible = false; // Toggle visibility
          }
        }
      });
    }
  });

  // Redraw the network with the updated shapes
  calculateLinkPositions();
  drawNetwork();
}
    initializePositions();

    for (let i = 0; i < 200; i++) {
      // Iterate for a certain number of steps to simulate convergence
      calculateForces();
      updatePositions();
    }

    calculateLinkPositions();
    drawNetwork();


    // Add click event listener
    svg.on('dblclick', handleMouseClick);

    // Cleanup the event listener on component unmount
    return () => {
      svg.on('dblclick', null);
    };

  }, [data, svgRef]);

  return <svg ref={svgRef} width="100%" height="100%" viewBox={`0 0 400 400`} preserveAspectRatio="xMidYMid meet"></svg>;
};

export default NetworkGraph;

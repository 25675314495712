import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const InteractomeTable = ({ interactomeData, setLoading, setGene, setProb }) => {
  const tableRef = useRef(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // Store the selected row

  const interactomeColumns = [
    { field: 'source', header: 'Molecule A' },
    { field: 'target', header: 'Molecule B' },
    { field: 'score', header: 'Distance' },
    { field: 'linkPos1', header: 'Link position A' },
    { field: 'linkPos2', header: 'Link position B' }
  ];

  const renderInteractomeColumns = interactomeColumns.map(col => {
    return <Column key={col.field} field={col.field} header={col.header}  />;
  });

  const handleRowSelect = (data) => {
  if (selectedRow === data.target) {
    return;
  }

  setSelectedRow(data.target);
  setLoading(true);
  setProb(0);
  setGene(data.target);
};


  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="vertical-align-middle font-bold line-height-3" role="button"
          onClick={() => handleRowSelect(data)}  title="Click to show the interactome"
          className={`cursor ${selectedRow === data.target ? 'selected-icon' : ''}`} >
          {data.target}
        </span>
      </React.Fragment>
    );
  };

  const exportCSV = (tableRef, selectionOnly) => {
    tableRef.current.exportCSV({ selectionOnly });
  };

  const exportExcel = async (dataToExport) => {
    const xlsx = await import('xlsx');
    const worksheet = xlsx.utils.json_to_sheet(dataToExport);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, 'data');
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const exportPdf = (dataToExport, columns) => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default('landscape');
        // Ensure columns are formatted as needed for jsPDF autoTable
        const formattedColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field
        }));
        doc.autoTable(formattedColumns, dataToExport);
        doc.save('data.pdf');
      });
    });
  };

  const headerInteractome = (
    <div className="row align-items-center">
      <div
        className="col"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <Button
          type="button"
          icon="pi pi-file"
          className="p-button-rounded p-mr-2"
          onClick={() => exportCSV(tableRef, false)}
          data-pr-tooltip="CSV"
        />
        <Button
          type="button"
          icon="pi pi-file-excel"
          className="p-button-success p-button-rounded p-mr-2"
          onClick={() => exportExcel(interactomeData)}
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          className="p-button-warning p-button-rounded"
          onClick={() => exportPdf(interactomeData, interactomeColumns)}
          data-pr-tooltip="PDF"
        />
      </div>
    </div>
  );

  return (
    <>
      <DataTable
        ref={tableRef}
        value={interactomeData}
        header={headerInteractome}
        rowGroupMode="subheader"
        groupRowsBy="target"
        sortMode="single"
        sortField="target"
        sortOrder={1}
        scrollable
        scrollHeight="400px"
        expandableRowGroups
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowGroupHeaderTemplate={headerTemplate}
        tableStyle={{ minWidth: '50rem' }}
      >
        {renderInteractomeColumns}
      </DataTable>
    </>
  );
};

export default InteractomeTable;

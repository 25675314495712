import React from 'react'

const Footer = () => {
  return (
<>
<footer className="footer bg-primary d-flex justify-content-center align-items-center vh-10">
  <div className="container text-center">
    <p className="fs--1 my-2 fw-bold text-200">
      All rights Reserved &copy; Bioinformatics group of the BIO@SNS, Scuola Normale Superiore, Pisa (Italy) - 2024
    </p>
  </div>
</footer>

</>
  )
}

export default Footer